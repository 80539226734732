@use "/styles/atoms/typography";
@use "/styles/atoms/mixins";
@use "/styles/vars";
@use "/styles/core";

.LeadTypeButton {
  background-color: transparent;
  border-radius: 8px;
  padding: 20px 40px;
  width: 100%;
  border: 2px solid #cecece;

  &:hover {
    border: 2px solid #027850;
  }

  span {
    &::before {
      border-radius: 8px;
    }
  }

  &__inner {
    @extend %body;
    @include core.media("max", "767px") {
      font-size: 16px;
      letter-spacing: -0.36px;
    }
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 12px;
  }

  svg {
    margin-bottom: 12px;
  }
}
